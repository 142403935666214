import React from "react"
import "antd/dist/antd.css"
import "../../styles/Home.css"
import "../../styles/style.css"
import "bootstrap/dist/css/bootstrap.min.css"
import ExploreLayout from "../Components/Layout/Explorelayout"

const Explore = () => {
  return (
    <div>
      <title>Payoasis Explore</title>
      <ExploreLayout />
    </div>
  )
}
export default Explore
