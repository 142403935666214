import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { Container } from "react-bootstrap"

const Exploreform = () => {

  const [name, setName] = useState('');
  const [companyname, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [comments, setComments] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    var formdata = new FormData();
    formdata.append("m", "ExploreForm");
    formdata.append("hmKey", "HomePage");
    formdata.append("name", name);
    formdata.append("companyname", companyname);
    formdata.append("designation",designation);
    formdata.append("emailaddress", email);
    formdata.append("phonenumber", phonenumber);
    formdata.append("comments", comments);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("http://localhost/PayS/PayOasis/www/html/mobile/api/index.php", requestOptions)
      .then(response => response.json())
      .then((result) => {
        if(result.Response == "Success"){
          alert("Form Has Been Submitted Succesfully");
          setName("");
          setCompany("");
          setDesignation("");
          setEmail("");
          setPhoneNumber("");
          setComments("");
        }
      })
      .catch(error => console.log('error', error));
  };

  return (
    <>
      <Container>
          <Fade>
              <h1 className="MainTopHeading">Explore Form</h1>
              <div className="formbg-outer">
                <div className="formbg">
                  <div className="formbg-inner padding-horizontal--48">
                    <span className="padding-bottom--15">Basic Information</span>
                    <form id="stripe-login"  onSubmit={handleSubmit}>
                      <div className="field padding-bottom--24">
                        <label for="text_name">Name</label>
                        <input type="text" name="text_name" value={name} onChange={(event) => setName(event.target.value)} />
                      </div>
                      <div className="field padding-bottom--24">
                        <label for="text_companyname">Company Name</label>
                        <input type="text" name="text_companyname" value={companyname} onChange={(event) => setCompany(event.target.value)} />
                      </div>
                      <div className="field padding-bottom--24">
                        <label for="text_designation">Desgination</label>
                        <input type="text" name="text_designation" value={designation} onChange={(event) => setDesignation(event.target.value)} />
                      </div>
                      <div className="field padding-bottom--24">
                        <label for="text_email">Email</label>
                        <input type="email" name="text_email" value={email} onChange={(event) => setEmail(event.target.value)} />
                      </div>
                      <div className="field padding-bottom--24">
                        <label for="text_number">Phone Number</label>
                        <input type="text" name="text_number" value={phonenumber} onChange={(event) => setPhoneNumber(event.target.value)} />
                      </div>
                      <div className="field padding-bottom--24">
                        <label for="text_comments">Comments</label>
                        <textarea name="text_comments" value={comments} onChange={(event) => setComments(event.target.value)}></textarea>
                      </div>
                      <div className="field padding-bottom--24">
                        <input type="submit" value="Submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
          </Fade>
      </Container>
    </>
  )
}

export default Exploreform
