import React from "react"
import Header from "../common/Header"
import Footer from "../common/Footer"
import Exploreform from "../Home/Exploreform"

const Explorelayout = () => {
  return (
    <>
      <Header />
      <Exploreform />
      <Footer />
    </>
  )
}
export default Explorelayout
